<template>
    <div v-if="page">
        <h2>{{$t('swap-title')}}</h2>
        <div v-if="!!page.doc.content" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <swap-table class="mb-3" :table-title="$t('eligible-packages')" :packages="page.viewer.packagesForSwap.map(p => {return this.transformData(p);})" :header="swapHeaders" :locked-rate="page.viewer.depositAddress"/>
    </div>
</template>

<script>
import gql from "graphql-tag";
import SwapTable from "@/components/Packages/SwapTable";
import * as moment from "moment";
import PageTitle from "@/components/common/PageTitle";

export default {
    name: "Swap",
    components: {
        PageTitle,
        SwapTable
    },
    data() {
        return {
            heading: this.$i18n.t("Packages"),
            subheading:
            "My Hotelium Packages",
            icon: "pe-7s-user icon-gradient bg-strong-bliss",
            page: null,
            swapHeaders: [
                {value: "id", text: this.$i18n.t("date"), sortable: false, class: "text-center"},
                {value: "expires", text: this.$i18n.t("loyalty-expires"), sortable: false},
                {value: "type", text: this.$i18n.t("type"), sortable: false},
                {value: "filled", text: this.$i18n.t("filled"), sortable: false},
                {value: "filledSwap", text: "Filled Swap", sortable: false},
                {value: "sellEligible", text: "Sell Eligible", sortable: false},
                {value: "valuePackageHTL", text: "Value Package HTL", sortable: false},
                {value: "valuePackageEUR", text: "Value Package EUR", sortable: false},
                // {value: "valueFromPackage", text: this.$i18n.t("value"), sortable: false},
                {value: "extraSharesA", text: "Extra Shares HTL A", sortable: false},
                {value: "actions", text: "Actions", sortable: false, class: "text-end"}
            ],
        }
    },
    apollo: {
        page: {
            query: gql`query page {
                doc(id:"packages"){content,title},
                viewer{id,
                    packagesForSwap{id,active,expiresAt,upgradedAt,packageName,totalValueInTokens,valueInFiat,noCommission,
                        allowedUpgrades{id,name,price,expiryDays,hash},sharesAFromLoyalty,dateSellEligible,paidValueInFiat,packageType
                    },
                    totals{tokens},
                    depositAddress{id,address,currency,expiresAt,lockedRate}
                },
                lockedRate{expiresAt,rate,rateCrypto,token,pack,currency,htlRate,upgrade,price},
                viewerIsAdmin
            }`,
            update: data => data,
            result({data}) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
    },
    methods: {
        transformData(p) {
            var result={};
            var now = new Date();
            
            {
                let fullDiff = moment(p.expiresAt).diff(moment(p.id));
                let diff = moment(now).diff(moment(p.id));
                Object.assign(p,{
                    diff: Math.min(Math.round(100 * diff / fullDiff),100),
                    color: p.valueInFiat[0] < 19000000 ? 'primary' : p.valueInFiat[0] < 900000000 ? 'success' : 'dark',
                });
            }

            {
                let fullDiff = moment(p.dateSellEligible).diff(moment(p.id));
                let diff = moment(now).diff(moment(p.id));
                let percentFilled=Math.min(Math.round(100 * diff / fullDiff),100);

                Object.assign(p,{
                    diffSwap: percentFilled,
                    colorSwap: "danger",
                    buttonSell: diff>=fullDiff,
                    // allowedUpgradesSwap: p.allowedUpgrades
                });                
            }

            return p;
        },
        expiredData(p) {
            return {
                color: p.valueInFiat[0] < 19000000 ? 'primary' : p.valueInFiat[0] < 900000000 ? 'success' : 'dark',
                ...p
            }
        },
    },
}
</script>

<style scoped>

</style>